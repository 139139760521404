<template>
  <!-- begin app-nabar -->
  <aside class="app-navbar">
    <!-- begin sidebar-nav -->
    <div class="sidebar-nav scrollbar scroll_light">
      <ul class="metismenu" id="sidebarNav">
        <li class="nav-static-title">Personal</li>
        <li class="active pointable">
          <router-link class to="/">
            <i class="nav-icon ti ti-dashboard"></i>
            <span class="nav-title">Dashboards</span>
          </router-link>
        </li>

        <li class>
          <a class="has-arrow pointable" aria-expanded="false">
            <i class="nav-icon ti ti-infinite"></i>
            <span class="nav-title">Access Management</span>
          </a>
          <ul aria-expanded="false">
            <li class="pointable">
              <router-link to="/users">Users</router-link>
            </li>
            <li class="pointable">
              <router-link to="/roles">Roles</router-link>
            </li>
            
          </ul>
        </li>

        <li class>
          <a class="has-arrow pointable" aria-expanded="false">
            <i class="nav-icon ti ti-game"></i>
            <span class="nav-title">Airtime Management</span>
          </a>
          <ul aria-expanded="false">
           <li class="pointable">
              <router-link to="/airtime/purchase">Purchase</router-link>
            </li>
            <li class="pointable">
              <router-link to="/airtime_networks">Networks</router-link>
            </li>
            <li class="pointable">
              <router-link to="/airtime_transactions">Transactions</router-link>
            </li>

            <li class="pointable">
              <router-link to="/failed_airtime_transactions">Failed Transactions</router-link>
            </li>
          </ul>
        </li>


        <li class>
          <a class="has-arrow pointable" aria-expanded="false">
            <i class="nav-icon ti ti-game"></i>
            <span class="nav-title">Data Management</span>
          </a>
          <ul aria-expanded="false">
             <li class="pointable">
              <router-link to="/data/purchase">Purchase</router-link>
            </li>
            <li class="pointable">
              <router-link to="/data_networks">Networks</router-link>
            </li>
            <li class="pointable">
              <router-link to="/data_transactions">Transactions</router-link>
            </li>

            <li class="pointable">
              <router-link to="/failed_data_transactions">Failed Transactions</router-link>
            </li>
          </ul>
        </li>

        

        <li class>
          <a class="has-arrow pointable" aria-expanded="false">
            <i class="nav-icon ti ti-harddrives"></i>
            <span class="nav-title">Cable-tv Management</span>
          </a>
          <ul aria-expanded="false">
            <li class="pointable">
              <router-link to="/cable/purchase">Buy</router-link>
            </li>
            <li class="pointable">
              <router-link to="/bouquet_type">Bouquet Type</router-link>
            </li>
            <li class="pointable">
              <router-link to="/cable_transanctions">Transactions</router-link>
            </li>

            <li class="pointable">
              <router-link to="/failed_cable_transactions">Failed Transactions</router-link>
            </li>
          </ul>
        </li>

        <li class>
          <a class="has-arrow pointable" aria-expanded="false">
            <i class="nav-icon ti ti-plug"></i>
            <span class="nav-title">Power Management</span>
          </a>
          <ul aria-expanded="false">
             <li class="pointable">
              <router-link to="/power/purchase">Purchase</router-link>
            </li>
            <li class="pointable">
              <router-link to="/power/discos">Discos</router-link>
            </li>
            <li class="pointable">
              <router-link to="/power/transaction">Transactions</router-link>
            </li>

            <li class="pointable">
              <router-link to="/power/failed_transaction">Failed Transactions</router-link>
            </li>
          </ul>
        </li>

        <!-- <li class>
          <a class="has-arrow pointable" aria-expanded="false">
            <i class="nav-icon ti ti-archive"></i>
            <span class="nav-title">Restaurant Management</span>
          </a>
          <ul aria-expanded="false">
            <li class="pointable">
              <router-link to="/restaurant">Restaurant</router-link>
            </li>
            <li class="pointable">
              <router-link to="/restaurant/categories">Categories</router-link>
            </li>
            <li class="pointable">
              <router-link to="/restaurant/menu_items">Menu Items</router-link>
            </li>
            <li class="pointable">
              <router-link to="/restaurant/routes">Routes</router-link>
            </li>
            <li class="pointable">
              <router-link to="/restaurant/orders">Orders</router-link>
            </li>
          </ul>
        </li> -->

        <!-- <li class>
          <a class="has-arrow pointable" aria-expanded="false">
            <i class="nav-icon ti ti-stamp"></i>
            <span class="nav-title">Store Management</span>
          </a>
          <ul aria-expanded="false">
            <li class="pointable">
              <router-link to="/store">Store</router-link>
            </li>
            <li class="pointable">
              <router-link to="/store/categories">Categories</router-link>
            </li>
            <li class="pointable">
              <router-link to="/store/items">Items</router-link>
            </li>
            <li class="pointable">
              <router-link to="/store/routes">Routes</router-link>
            </li>
            <li class="pointable">
              <router-link to="/store/orders">Orders</router-link>
            </li>
          </ul>
        </li> -->

         <!-- <li class>
          <a class="has-arrow pointable" aria-expanded="false">
            <i class="nav-icon ti ti-location-pin"></i>
            <span class="nav-title">Dispatch Management</span>
          </a>
          <ul aria-expanded="false">
           
            <li class="pointable">
              <router-link to="/dispatch/routes">Routes</router-link>
            </li>
            <li class="pointable">
              <router-link to="/dispatch/orders">Orders</router-link>
            </li>
          </ul>
        </li> -->

         <!-- <li class>
          <a class="has-arrow pointable" aria-expanded="false">
            <i class="nav-icon ti ti-money"></i>
            <span class="nav-title">Services Management</span>
          </a>
          <ul aria-expanded="false">
           
            <li class="pointable">
              <router-link to="/services">Services</router-link>
            </li>
            <li class="pointable">
              <router-link to="/services/artisans">Artisans</router-link>
            </li>
            <li class="pointable">
              <router-link to="/services/requests">Request</router-link>
            </li>
          </ul>
        </li> -->

        <!-- <li class>
          <a class="has-arrow pointable" aria-expanded="false">
            <i class="nav-icon ti ti-money"></i>
            <span class="nav-title">Movies Management</span>
          </a>
          <ul aria-expanded="false">
           
            <li class="pointable">
              <router-link to="/cinemas">Cinema</router-link>
            </li>
            <li class="pointable">
              <router-link to="/movies">Movies</router-link>
            </li>
            <li class="pointable">
              <router-link to="/movies/bookings">Bookings</router-link>
            </li>
          </ul>
        </li> -->

        <!-- <li class>
          <a class="has-arrow pointable" aria-expanded="false">
            <i class="nav-icon ti ti-money"></i>
            <span class="nav-title">Events Management</span>
          </a>
          <ul aria-expanded="false">
           
            <li class="pointable">
              <router-link to="/events">Events</router-link>
            </li>
         
            <li class="pointable">
              <router-link to="/events/bookings">Bookings</router-link>
            </li>
          </ul>
        </li> -->

         <!-- <li class>
          <a class="has-arrow pointable" aria-expanded="false">
            <i class="nav-icon ti ti-money"></i>
            <span class="nav-title">Physician Management</span>
          </a>
          <ul aria-expanded="false">
           
            <li class="pointable">
              <router-link to="/physician">Physician</router-link>
            </li>
         
            <li class="pointable">
              <router-link to="/physician/requests">Requests</router-link>
            </li>
          </ul>
        </li> -->

         <li class>
          <a class="has-arrow pointable" aria-expanded="false">
            <i class="nav-icon ti ti-money"></i>
            <span class="nav-title">Bank Management</span>
          </a>
          <ul aria-expanded="false">
           
            <li class="pointable">
              <router-link to="/banks">Banks</router-link>
            </li>
            <li class="pointable">
              <router-link to="/banks/transactions">Transactions</router-link>
            </li>
            <li class="pointable">
              <router-link to="/banks/failed_transactions">Failed Transactions</router-link>
            </li>
          </ul>
        </li>


         <!-- <li class>
          <a class="has-arrow pointable" aria-expanded="false">
            <i class="nav-icon ti ti-money"></i>
            <span class="nav-title">Location Management</span>
          </a>
          <ul aria-expanded="false">
           
            <li class="pointable">
              <router-link to="/states">States</router-link>
            </li>

            <li class="pointable">
              <router-link to="/locations">Locations</router-link>
            </li>
          
          </ul>
        </li> -->


        <li class>
          <a class="has-arrow pointable" aria-expanded="false">
            <i class="nav-icon ti ti-money"></i>
            <span class="nav-title">Wallet Management</span>
          </a>
          <ul aria-expanded="false">
           
            <li class="pointable">
              <router-link to="/wallet_wallet">Wallet-Wallet Transfer</router-link>
            </li>

            <li class="pointable">
              <router-link to="/wallets_transaction">Wallet Transaction</router-link>
            </li>
          
          </ul>
        </li>

        <li class>
          <a class="has-arrow pointable" aria-expanded="false">
            <i class="nav-icon ti ti-money"></i>
            <span class="nav-title">Task Management</span>
          </a>
          <ul aria-expanded="false">
           
            <li class="pointable">
              <router-link to="/tasks">Tasks</router-link>
            </li>

            <li class="pointable">
              <router-link to="/tasks/subtask">Sub Tasks</router-link>
            </li>
          </ul>
        </li>

        <li class>
          <a class="has-arrow pointable" aria-expanded="false">
            <i class="nav-icon ti ti-money"></i>
            <span class="nav-title">Callback Transaction</span>
          </a>
          <ul aria-expanded="false">
           
            <li class="pointable">
              <router-link to="/callback">Tasks</router-link>
            </li>

          
          </ul>
        </li>

      
        <hr style="width: 100%; color: white;" />
        <li class="pointable">
          <router-link class to="/app_settings">
            <i class="nav-icon ti ti-settings"></i>
            <span class="nav-title">Settings</span>
          </router-link>
        </li>

        <li class="pointable">
          <router-link class to="/voucher">
            <i class="nav-icon ti ti-credit-card"></i>
            <span class="nav-title">Voucher</span>
          </router-link>
        </li>

        
      </ul>
    </div>
    <!-- end sidebar-nav -->
  </aside>
  <!-- end app-navbar -->
</template>

<script>
export default {};
</script>

<style scoped>
.pointable {
  cursor: pointer;
}
</style>