<template>
  <!-- begin footer -->
  <footer class="footer">
    <div class="row">
      <div class="col-12 col-sm-6 text-center text-sm-left">
        <p>&copy; Copyright {{  new Date().toISOString().slice(0, 4) }}. All rights reserved.</p>
      </div>
      <div class="col col-sm-6 ml-sm-auto text-center text-sm-right">
        <p>
          Hand-crafted made with
          <i class="fa fa-heart text-danger mx-1"></i> by CreativeTribe
        </p>
      </div>
    </div>
  </footer>
  <!-- end footer -->
</template>

<script>
export default {};
</script>

<style scoped>
</style>